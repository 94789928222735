<template>
  <Toast />
  <div class="flex align-items-center py-5 px-0">
    <div class="p-4 w-full">
      <div class="text-center mb-5">
        <div class="text-900 font-medium mb-3">Enter your new password.</div>
      </div>

      <div>
        <InputText
          id="password"
          type="password"
          v-model="v$.password.password.$model"
          class="w-full mb-3"
          :class="{ 'p-invalid': v$.password.password.$invalid && submitted }"
        />
        <small
          v-if="
            (v$.password.password.$invalid && submitted) ||
            v$.password.password.$pending.$response
          "
          class="p-error"
          >{{
            v$.password.password.required.$message.replace("Value", "Password")
          }}</small
        >
        <InputText
          id="confirmPassword"
          type="password"
          v-model="v$.password.confirm.$model"
          @keyup.enter="submit"
          class="w-full mb-3"
          :class="{
            'p-invalid': v$.password.confirm.$invalid && submitted,
            'border-green-400':
              !v$.password.confirm.$invalid && v$.password.confirm.$dirty,
          }"
        />
        <small
          v-if="v$.password.confirm.$invalid && v$.password.confirm.$dirty"
          class="p-error"
          >Passwords do not match.</small
        >
        <small
          v-else-if="
            !v$.password.confirm.$invalid && v$.password.confirm.$dirty
          "
          class="text-green-400"
          >Passwords are a match.</small
        >
        <br />

        <br />
        <div class="flex justify-content-center flex-wrap">
          <Button
            label="Reset Password"
            class="w-1/4"
            v-on:click="submit"
          ></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../../service/UserService";
import { required, sameAs } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["uid"],
  data() {
    return {
      v$: useVuelidate(),
      password: {
        password: "",
        confirm: "",
      },
      submitted: false,
    };
  },
  validations() {
    return {
      password: {
        password: { required },
        confirm: { sameAsPassword: sameAs(this.password.password) },
      },
    };
  },
  userService: null,
  created() {
    this.userService = new UserService();
  },
  methods: {
    submit() {
      // console.log(this.v$);
      this.submitted = true;
      // this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        this.userService
          .resetpassword({
            id: this.uid,
            password: this.password.password,
          })
          .then((data) => {
            if ("error" in data) {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: data["error"],
                life: 3000,
              });
            } else {
              // this.$emit("codeVerified", true);
              this.$router.push("/login");
            }
          });
      }
    },
  },
};
</script>
