<template>
  <Toast />
  <div class="flex align-items-center py-5 px-0">
    <div class="p-4 w-full">
      <div class="text-center mb-5">
        <!-- <img
          src="images/coachera-logo-sm.png"
          alt="Image"
          height="50"
          class="mb-3"
        /> -->
        <div class="text-900 font-medium mb-3">
          Enter your Email and we will send you a password reset code after
          successfully verifying your email, that you may enter in the next step
          to reset your password.
        </div>
      </div>

      <div>
        <!-- <label
          for="email"
          class="block text-900 font-medium mb-2"
          :class="{ 'p-error': v$.email.$invalid && submitted }"
          >E-Mail</label
        > -->
        <InputText
          id="email"
          type="text"
          class="w-full mb-3"
          :class="{ 'p-invalid': v$.email.$invalid && submitted }"
          v-model="email"
          v-on:keyup.enter="submit"
        />
        <span
          v-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response"
        >
          <span id="email-error">
            <small class="p-error">{{ v$.email.email.$message }}</small>
            <br />
            <small v-if="v$.email.required.$invalid" class="p-error">{{
              v$.email.required.$message.replace("Value", "Email")
            }}</small>
          </span>
        </span>
        <br />
        <div class="flex justify-content-center flex-wrap">
          <Button
            class="w-1/4 mr-2"
            @click="this.$router.go(-1)"
            icon="pi pi-arrow-left"
          >
          </Button>
          <Button
            label="Verify"
            icon="pi pi-user"
            class="w-1/4"
            v-on:click="submit"
          ></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../../service/UserService";
import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  data() {
    return {
      v$: useVuelidate(),
      email: "",
      submitted: false,
      id: -1,
      email_verified: false,
    };
  },
  userService: null,
  created() {
    this.userService = new UserService();
  },
  emits: ["uidChanged"],
  validations() {
    return {
      email: {
        required,
        email,
      },
    };
  },
  methods: {
    submit() {
      this.submitted = true;
      if (this.v$.$invalid) {
        return;
      } else {
        this.userService
          .forgotpassword({
            email: this.email,
          })
          .then((data) => {
            if ("error" in data) {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: data["error"],
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: "Account Exists",
                life: 3000,
              });
              this.id = data["uid"];
              this.email_verified = true;
              this.$emit("uidChanged", this.id, this.email_verified);
            }
          });
      }
    },
  },
};
</script>
