<template>
  <div class="justify-center">
    <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
      <div class="text-center mb-5">
        <h5>Forgot Password</h5>
        <EnterEmail
          v-if="!email_verified"
          @uid-changed="logverifyemail"
        ></EnterEmail>
        <EnterResetCode
          v-if="email_verified && !code_verified"
          :uid="id"
          @code-verified="logverifycode"
        ></EnterResetCode>
        <ResetPassword v-if="code_verified" :uid="id"></ResetPassword>
      </div>
    </div>
  </div>
</template>

<script>
import EnterEmail from "@/components/c_forgot_password/EnterEmail.vue";
import EnterResetCode from "@/components/c_forgot_password/EnterResetCode.vue";
import ResetPassword from "@/components/c_forgot_password/ResetPassword.vue";

export default {
  components: {
    EnterEmail,
    EnterResetCode,
    ResetPassword,
  },
  data() {
    return {
      email_verified: false,
      code_verified: false,
      id: -1,
    };
  },

  methods: {
    logverifyemail(id, verified) {
      // console.log(id);
      this.id = id;
      this.email_verified = verified;
    },
    logverifycode(verified) {
      this.code_verified = verified;
    },
  },
};
</script>
<style lang="scss">
@media (max-width: 450px) {
  .layout-wrapper .layout-main-container {
    padding-left: 0rem !important;
    margin: 0rem !important;
  }
  .layout-main-container {
    padding: 7rem 0rem 2rem 0rem !important;
  }
}
</style>
